import { lazy, useState } from "react";
import { BrowserRouter, Route, Routes, } from "react-router-dom";

const UserRoutes = lazy(() => import("./UserRoutes"));
const AdminRoutes = lazy(() => import("./admin/AdminRoutes"));

export default function App(props) {
  const [navTitle, setNavTitle] = useState(null);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminRoutes navTitle={navTitle} setNavTitle={setNavTitle}/>} />
        <Route path="/*" element={<UserRoutes navTitle={navTitle} setNavTitle={setNavTitle} />} />
      </Routes>
    </BrowserRouter>
  );
}